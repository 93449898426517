import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {BasicModal, CustomCheckbox, CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import classNames from "classnames";
import {toJS} from "mobx";
import {Checkbox} from "@material-ui/core";
import {toast} from "react-toastify";

const columnList = [
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'nickname',
        title: '별칭',
        width: '9',
    },
    {
        code: 'menuGroup',
        title: '메뉴그룹',
        width: '8',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '8',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'place.name',
        title: '장소명',
        width: '8',
    },
    {
        code: 'createdDatetime',
        title: '생성 일시',
        width: '8',
    },
    {
        code: '',
        title: '자동화 설정',
        width: '5',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '디바이스',
        width: '5',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '알림설정',
        width: '5',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '수신동의',
        width: '5',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '알림톡',
        width: '5',
        tdClickEvent: true,
    },
    {
        code: 'enabled',
        title: '사용여부',
        width: '4',
    },
];

export const UserList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '메뉴그룹 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 20,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        temporary : false,
        searchText: '',
        searchText2: '',
        searchText3: '',
        searchText4: '',
        searchText5: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: ''
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        username: '',
        clickedBtnName: '',
    });

    const [programAuth, setProgramAuth] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = '사용자 목록';
        getCategoryList('');
        pageAuthInfo();

    }, [])

    useEffect(() => {
        loadData()
    }, [pageState.currentPage,
        pageState.rowPerPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
        pageState.searchText,
        pageState.searchText2,
        pageState.searchText3,
        pageState.searchText4,
        pageState.searchText5,
        pageState.temporary
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getUserList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchText2: pageState.searchText2,
                searchText3: pageState.searchText3,
                searchText4: pageState.searchText4,
                searchText5: pageState.searchText5,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: pageState.searchCategory3,
                role : pageState.temporary ? "TEMPORARY" : false
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const getMenuGroupList = (callback) => {
        Net.getCodeList('MENU_GROUP', (response) => {
            let newArray = searchCategory[2][0];
            response.data.forEach(items => {
                newArray = [
                    ...newArray,
                    {
                        code: items.code,
                        name: items.name,
                    }
                ]
            });
            callback({status: 200, data: newArray});
        });
    }

    const getCategoryList = () => {
        let response3 = [];
        getMenuGroupList(response => {
            if (response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    getCustomerList((response1) => {
                        getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                }
            }
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0], // 고객사
                response2,         // 현장
                searchCategory[2], // 메뉴그룹
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const addList = () => {
        history.push('/member/user/regist');
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeUserList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const username = nodeTr.querySelector('td[name=username]').textContent;
            removeUserList.push({username: username});
        });

        if (result) {
            Net.removeUserList(removeUserList, (response) => {
                if (response) {
                    loadData();
                }
            });
        }
    }

    const onClickRow = (e) => {
        if(user.menuGroup !== 'OPERATOR') {
            history.push(`/member/user/regist/${e.username}`);
        }
    }

    const tdOnClickEvent = async (title, e) => {
        if (title === "알림톡") {
            let response = false;
            if (e.customer.code === 'KGC') {
                response = window.confirm('코오롱글로벌 입주민에게 알림톡을 전송하시겠습니까?\n\n' + '전화 번호 : ' + e.phoneNumber + '\n아이디 : ' + e.username);
            } else {
                alert('관리자에게 문의해주세요.');
            }

            if (response) {
                const responseSendTalk = await Net.sendWelcomeTalkAsync({
                    phoneNumber : e.phoneNumber,
                    username : e.username
                });

                if(responseSendTalk){
                    toast('전송 완료', {
                        autoClose: 1500,
                        pauseOnHover: false,
                        draggable: false,
                        position: "top-center"
                    });
                }

            }
        } else {
            onClickModalOpen(title, e);
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
                searchText5: e.searchText5,

            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
                searchText5: e.searchText5,


            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: pageState.searchCategory3,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
                searchText5: e.searchText5,

            });
        }
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (type, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            username: e.username,
            clickedBtnName: type
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-80"
                title={`사용자 ${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        username={modalState.username}/>
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    role={user.menuGroup}
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchText2={true}
                    searchText3={true}
                    searchText4={true}
                    searchText5={true}
                    searchPlaceHolder="사용자아이디 "
                    searchPlaceHolder2="닉네임 "
                    searchPlaceHolder3="전화번호 "
                    searchPlaceHolder4="동"
                    searchPlaceHolder5="호"
                    smSearchText={true}
                    smSearchText2={true}
                    smSearchText3={true}
                    smSearchText4={true}
                    smSearchText5={true}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-100">
                <p className={classNames("totalCnt")}>Total {pageState.totalElementsCnt}</p>
                {
                    programAuth.deleteAuth && checkedList.length > 0 &&
                    <button
                        className="cancelBtn w-90px h-30px floatR mt-15 mr-20"
                        onClick={removeList}
                    >삭제</button>
                }
                <span className="text-muted ml-3 mt-2">임시 사용자만</span>
                <Checkbox
                    checked={pageState.temporary}
                    onChange={() => setPageState({
                        ...pageState,
                        temporary: !pageState.temporary
                    })}
                    disabled={loading}
                />
                {
                    programAuth.saveAuth &&
                    <button
                        className="addBtn w-90px h-30px floatR mt-15 mr-20"
                        onClick={addList}
                    >등록
                    </button>
                }
                <button
                    className="removeBtn w-150px h-30px floatR mt-15 mr-20"
                    >페이지 행 수 지정</button>
                <input
                    className="searchText sm-searchText mr-10 mt-15 floatR"
                    type="text"
                    value={pageState.rowPerPage}
                    onChange={(e)=>setPageState({
                        ...pageState,
                        rowPerPage: e.target.value,
                        currentPage: 1
                    })}/>

                <CustomTable
                    columnList={columnList}
                    contentData={contentData}
                    checkbox={programAuth.deleteAuth}
                    checkedList={(checkedList) => {
                        setCheckedList(checkedList)
                    }}
                    paging={true}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={pageState.rowPerPage}
                    currentPage={pageState.currentPage - 1}
                    drawElementsCnt={pageState.drawElementsCnt}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    tdClickEvt={tdOnClickEvent}
                    loading={loading}
                    // sort = {true}
                />
            </div>
        </div>
    )
}

export const ModalContent = (props) => {

    switch (props.modalType) {
        case "디바이스":
            return <DeviceModalContent username={props.username}/>
            break;
        case "자동화 설정":
            return <AutomationModalContent username={props.username}/>
            break;
        case "알림설정":
            return <NotiModalContent username={props.username}/>
            break;
        case "수신동의":
            return <AgreeModalContent username={props.username}/>
            break;
        default:
            break;
    }

}

export const DeviceModalContent = (props) => {
    const modalColumnList = [
        {
            code: 'deviceId',
            title: '디바이스 ID',
            width: '20',
        },
        {
            code: 'name',
            title: '디바이스명',
            width: '10',
        },
        {
            code: 'serialNumber',
            title: 'SerialNumber',
            width: '20',
        },
        {
            code: 'model.name',
            title: '모델명',
            width: '10',
        },
        {
            code: 'model.type.name',
            title: '장비타입',
            width: '10',
        },
        {
            code: 'model.manufacturer.name',
            title: '제조사명',
            width: '10',
        },
    ];

    const [propertyList, setpropertyList] = useState([]);

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getUserDeviceList({username: props.username},
            (response) => {
                if (response.status === 200) {
                    setpropertyList(response.data);
                } else {
                    setpropertyList([]);
                }
            })
    }, [])

    return (
        <div>
            <p className={classNames("totalCnt")} style={{"marginTop": "0"}}>Total {propertyList.length}</p>
            <CustomTable
                className={"w-100 h-57vh overflowYscroll"}
                columnList={modalColumnList}
                contentData={propertyList}
                checkbox={false}
                rowClickEvt={false}
                paging={false}
            />
        </div>
    )
}

export const AutomationModalContent = (props) => {
    const modalColumnList = [
        {
            code: 'id',
            title: '자동화모드 코드',
            width: '50',
        },
        {
            code: 'name',
            title: '자동화 모드명',
            width: '30',
        },
        {
            code: 'enabled',
            title: '활성화여부',
            width: '20',
        },
    ];

    const actionColumnList = [
        {
            code: 'deviceName',
            title: '장비명',
            width: '25',
        },
        {
            code: 'operations.name',
            title: '장비속성',
            width: '25',
        },
        {
            code: 'operations.value',
            title: '설정상태',
            width: '25',
        },
        {
            code: 'timer',
            title: '지연시간',
            width: '25',
        },
    ];

    const [propertyList, setpropertyList] = useState([]);
    const [propertyDetail, setpropertyDetail] = useState([]);
    const [actionList, setActionList] = useState([]);

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getUserAutomationList(
            props.username, (response) => {
                if (response.status === 200) {
                    setpropertyList(response.data.contents);
                } else {
                    setpropertyList([]);
                }
            })
    }, [])

    const onClickRow = (e) => {
        const automationId = e.id;
        Net.getUserAutomationOne(
            automationId, (response) => {
                if (response.status === 200) {
                    setpropertyDetail(response.data);

                    response.data.actions.map(action => {
                        action.operations = action.operations[0];
                    })
                    setActionList(response.data.actions);
                } else {
                    setpropertyDetail([]);
                    setActionList([]);
                }
            }
        )

    }
    const weekList = [
        {code: "MONDAY", name: "월"},
        {code: "TUESDAY", name: "화"},
        {code: "WEDNESDAY", name: "수"},
        {code: "THURSDAY", name: "목"},
        {code: "FRIDAY", name: "금"},
        {code: "SATURDAY", name: "토"},
        {code: "SUNDAY", name: "일"},
    ];

    return (
        <div className="flex h-90">
            <div className="w-50 h-100">
                <CustomTable
                    className="overFlowYscrollView"
                    columnList={modalColumnList}
                    contentData={propertyList}
                    checkbox={false}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    paging={false}
                />
            </div>
            <div className="w-50 h-100 ml-10">
                <div className="w-100 h-30" style={{display: "block"}}>
                    {
                        propertyDetail.type === "PERIODIC" ?
                            <Fragment>
                                <label className="label_title">기간조건</label>
                                <div className="ml-30 mt-10">
                                    <div className="flex mb-10">
                                        <label className="label_title" style={{width: "55px"}}>요일</label>
                                        {
                                            weekList.map((item, i) => {
                                                return (
                                                    <CustomCheckbox
                                                        key={i}
                                                        id={`week_${i}`}
                                                        className="j-c-c h-16px"
                                                        checked={propertyDetail.periodicCondition && propertyDetail.periodicCondition.days.includes(item.code)}
                                                        title={item.name}
                                                        disabled={true}
                                                        className="mr-15"
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                        <label className="label_title" style={{width: "50px"}}>시작시간</label>
                                        <input className="ml-10" type="text" value={propertyDetail.periodicCondition && propertyDetail.periodicCondition.time} readOnly disabled></input>
                                    </div>
                                </div>
                            </Fragment>
                            :
                            <div className="w-100 h-30">
                                <label className="label_title">상태조건</label>
                                <table className="mainTable">
                                    <thead className="tableHead">
                                    <tr>
                                        <th className="v-middle">장비명</th>
                                        <th className="v-middle">장비속성</th>
                                        <th className="v-middle">설정상태</th>
                                    </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                    {
                                        propertyDetail.statusCondition
                                            ?
                                            <tr>
                                                <td>
                                                    {propertyDetail.statusCondition.deviceName}
                                                </td>
                                                <td>
                                                    {propertyDetail.statusCondition.attributeName}
                                                </td>
                                                <td>
                                                    {propertyDetail.statusCondition.attributeValue}
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="3">조회 된 데이타가 없습니다.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
                <div className="w-100 h-70 mt-12" style={{display: "block"}}>
                    <label className="label_title">액션</label>
                    <CustomTable
                        className="overFlowYscrollView"
                        columnList={actionColumnList}
                        contentData={actionList}
                        checkbox={false}
                        rowClickEvt={false}
                        paging={false}
                    />
                </div>

            </div>
        </div>
    )

}

export const NotiModalContent = (props) => {
    const modalColumnList = [
        {
            code: 'notificationType',
            title: '알림명',
            width: '20',
        },
        {
            code: 'enabled',
            title: '상태',
            width: '10',
        },

    ];

    const [propertyList, setpropertyList] = useState([]);

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getUserNotificationList(props.username,
            (response) => {
                if (response.status === 200) {
                    const list = response.data.notifications ? response.data.notifications : [];
                    setpropertyList(list);
                } else {
                    setpropertyList([]);
                }
            })
    }, [])

    return (
        <table className="mainTable">
            <thead className="tableHead">
            <tr>
                <th className="v-middle" width={"100px"}>알람명</th>
                <th className="">카테고리</th>
                <th className="v-middle" width={"100px"}>상태</th>
            </tr>
            </thead>
            <tbody className="tableBody">
            {

                propertyList.length > 0
                    ?
                    propertyList.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    {item.notificationType}
                                </td>
                                <td style={{"whiteSpace": "normal"}}>
                                    {item.notificationCategories && item.notificationCategories.join(", ")}
                                </td>
                                <td>
                                    <CustomCheckbox
                                        id={`model_row_${i}`}
                                        className="j-c-c h-16px"
                                        title=""
                                        checked={item.enabled}
                                        disabled={true}
                                    />
                                </td>
                            </tr>

                        )

                    })
                    :
                    <tr>
                        <td colSpan="3">조회 된 데이타가 없습니다.</td>
                    </tr>
            }

            </tbody>
        </table>
    )
}

export const AgreeModalContent = (props) => {
    const [propertyList, setpropertyList] = useState([]);

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getUserOne(props.username,
            (response) => {
                if (response.status === 200) {
                    const list = response.data.agreeList ? response.data.agreeList : [];
                    setpropertyList(list);
                } else {
                    setpropertyList([]);
                }
            })
    }, [])

    return (
        <table className="mainTable">
            <thead className="tableHead">
            <tr>
                <th className="v-middle">수신동의</th>
            </tr>
            </thead>
            <tbody className="tableBody">
            {

                propertyList.length > 0
                    ?
                    propertyList.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>
                                    {item}
                                </td>
                            </tr>

                        )

                    })
                    :
                    <tr>
                        <td colSpan="1">조회 된 데이타가 없습니다.</td>
                    </tr>
            }

            </tbody>
        </table>
    )
}
